import {
  Box,
  Card,
  CircularProgress,
  CssBaseline,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useParams } from "react-router-dom";

import SessionApi from "../api/session.api";

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: "auto",
  backgroundImage:
    "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
  backgroundRepeat: "no-repeat",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    height: "100dvh",
  },
  ...theme.applyStyles("dark", {
    backgroundImage:
      "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
  }),
}));

const MainCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

export default function ConfirmationEmail() {
  const { token } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [verified, setVerified] = React.useState(false);

  React.useEffect(() => {
    async function verifyEmail() {
      if (token) {
        try {
          await SessionApi.confirmEmail(token);
          setVerified(true);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    }

    verifyEmail();
  }, [token]);

  return (
    <Grid container component={"main"} sx={{ height: "100vh" }}>
      <CssBaseline />
      <SignInContainer direction="column" justifyContent="space-between">
        <Stack
          sx={{
            justifyContent: "center",
            height: "100dvh",
            p: 2,
          }}
        >
          <MainCard variant="outlined">
            <Typography
              component="h1"
              variant="h4"
              align={"center"}
              sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
            >
              {!loading && verified ? "Email Confirmed" : "Confirming Email"}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loading && <CircularProgress size={70} />}
            </Box>
          </MainCard>
        </Stack>
      </SignInContainer>
    </Grid>
  );
}
