import React from "react";

import {
  BusinessUnit,
  ColumnHeaders,
  FileRow,
  FileUploaderContextType,
  FileUploaderState,
  FileUtilityType,
  IFile,
  ValidatedFileRow,
} from "../types";

export const FileUploaderContext = React.createContext<
  Partial<FileUploaderContextType>
>({
  businessUnit: undefined,
  columnHeaders: undefined,
  fileUploaderState: "initial_state",
  setFileUploaderState: (arg: FileUploaderState) => {},
  fileUtilityType: "sales",
  headerRowIndex: undefined,
  month: undefined,
  setHeaderRowIndex: (arg?: number) => {},
  validatedFileRows: [],
  worksheetData: undefined,
  setWorksheetData: (arg?: any) => {},
  submittedRows: [],
  setSubmittedRows: (arg: any[]) => {},
  year: undefined,
});

const FileUploaderProvider: React.FC<{ children: React.ReactNode }> = (
  { children },
) => {
  const [businessUnit, setBusinessUnit] = React.useState<
    BusinessUnit | undefined
  >(undefined); // [1
  const [columnHeaders, setColumnHeaders] = React.useState<
    ColumnHeaders | undefined
  >(undefined);
  const [fileUploaderState, setFileUploaderState] =
    React.useState<FileUploaderState>("initial_state");
  const [fileUtilityType, setFileUtilityType] =
    React.useState<FileUtilityType>("sales");
  const [headerRowIndex, setHeaderRowIndex] = React.useState<
    number | undefined
  >(undefined);
  const [validatedFileRows, setValidatedFileRows] = React.useState<
    ValidatedFileRow[]
  >([]);
  const [submittedRows, setSubmittedRows] = React.useState<FileRow[]>([]);
  const [worksheetData, setWorksheetData] = React.useState<any>(undefined);
  const [month, setMonth] = React.useState<number | undefined>(undefined);
  const [year, setYear] = React.useState<number | undefined>(undefined);
  return (
    <FileUploaderContext.Provider
      value={{
        businessUnit,
        setBusinessUnit,
        columnHeaders,
        setColumnHeaders,
        fileUploaderState,
        setFileUploaderState,
        fileUtilityType,
        setFileUtilityType,
        headerRowIndex,
        setHeaderRowIndex,
        month,
        setMonth,
        validatedFileRows,
        setValidatedFileRows,
        worksheetData,
        setWorksheetData,
        submittedRows,
        setSubmittedRows,
        year,
        setYear,
      }}
    >
      {children}
    </FileUploaderContext.Provider>
  );
};

export function useFileUploaderContext() {
  return React.useContext(FileUploaderContext);
}

export default FileUploaderProvider;
