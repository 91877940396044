import { GridRowModel } from "@mui/x-data-grid";
import { useMutation, useQuery } from "react-query";

import { BusinessUnit, FileUtilityType, SavedHeaderSchema, FileRow } from "../types";
import {
  handleBasicFetch,
  handleBasicPost,
  handleMultiPartFormPost,
} from "./handlers";

export default {
  // getFiles: async function getFiles(): Promise<File[]> {
  //   try {
  //     const resp = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/files`);
  //     return await resp.json();
  //   } catch (error) {
  //     console.error(error);
  //     return [];
  //   }
  // },
  useFetchSavedHeaderSchemas: ({
    fileUtilityType,
    query,
  }: {
    fileUtilityType?: FileUtilityType;
    query?: string;
  }) => {
    return useQuery<SavedHeaderSchema[]>({
      enabled: !!fileUtilityType,
      queryKey: ["saved_header_schemas", fileUtilityType],
      queryFn: () =>
        handleBasicFetch({
          url: "saved-header-schemas",
          params: [
            { key: "query", value: query },
            { key: "fileUtilityType", value: fileUtilityType },
          ],
        }),
    });
  },
  useFetchSavedHeaderSchema: (id?: number) => {
    return useQuery<SavedHeaderSchema>({
      enabled: !!id,
      queryKey: ["saved_header_schema", id?.toString()],
      queryFn: () =>
        handleBasicFetch({
          url: `saved-header-schemas/${id}`,
          params: [],
        }),
    });
  },
  useCreateSavedHeaderSchemas: ({
    fileUtilityType,
    name,
    columnHeaders,
  }: {
    fileUtilityType: FileUtilityType;
    name: string;
    columnHeaders: { [key: number]: string };
  }) => {
    return useMutation<SavedHeaderSchema>({
      mutationFn: () =>
        handleBasicPost({
          url: "saved-header-schemas",
          input: {
            fileUtilityType,
            name,
            columnHeaders,
          },
        }),
    });
  },
  useUploadValidFileData: () => {
    return useMutation({
      mutationFn: ({
        fileUtilityType,
        rows,
        businessUnit,
        month,
        year,
      }: {
        fileUtilityType: FileUtilityType;
        rows: readonly GridRowModel[];
        businessUnit?: BusinessUnit;
        month?: number;
        year?: number;
      }): Promise<{ status: string, data: FileRow[] }> => {
        const formData = new FormData();
        if (businessUnit) {
          formData.append("businessUnitId", businessUnit?.id?.toString());
        }
        if (month) {
          formData.append("month", month?.toString());
        }
        if (year) {
          formData.append("year", year?.toString());
        }
        formData.append("fileUtilityType", fileUtilityType);
        formData.append("rows", JSON.stringify(rows));
        return handleMultiPartFormPost({
          url: "sales",
          formData,
        });
      },
    });
  },
};
