import BusinessUnitApi from "../../api/businessUnit.api";
import React from "react";
import {BusinessUnit} from "../../types";
import {Box, Button, TextField} from "@mui/material";
import toast from "react-hot-toast";


export  default function BusinessUnitForm({ businessUnit }: { businessUnit: BusinessUnit }) {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [ name, setName ] = React.useState<string>(businessUnit.name)

  const { mutateAsync } = BusinessUnitApi.useSave()

  const handleSave = async () => {
    try {
      setLoading(true)
      const result = await mutateAsync({ id: businessUnit.id, name })
      console.log('result', result)
      toast.success("Successfully saved.")
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box sx={{display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box>
        <TextField fullWidth={true} label="Name" value={name} onChange={(e) => setName(e.target.value)} />
      </Box>
      <Box>
        <Button variant="contained" fullWidth={true} onClick={handleSave}>Save</Button>
      </Box>
    </Box>
  )
}