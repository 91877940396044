import dayjs from "dayjs";
import lodash from "lodash";

import {
  FileHeader,
  FileUtilityType,
  ValidatedFileRow,
  ValidationFunc,
} from "../types";

export function excelHeaderName(i: number) {
  const secondaryCharNum = i % 26;
  const primaryCharNum = i / 26;
  if (primaryCharNum >= 1) {
    const primaryChar = String.fromCharCode(64 + Math.floor(primaryCharNum));
    const secondaryChar = String.fromCharCode(
      65 + Math.floor(secondaryCharNum),
    );
    return `${primaryChar}${secondaryChar}`;
  }

  return String.fromCharCode(65 + secondaryCharNum);
}

export function validateRow({
  fileRow,
}: {
  fileRow: {
    [key: number]: {
      fileHeader: FileHeader;
      value: any;
    };
  };
}): ValidatedFileRow {
  const rowValues: { [key: string]: boolean } = {};
  Object.values(fileRow).forEach((cell) => {
    rowValues[cell.fileHeader.key] = cell.value;
  });

  const valid: { [key: string]: boolean } = {};
  Object.keys(fileRow).forEach(
    (columnKey: string) => (valid[columnKey] = true),
  );
  let error = undefined;
  Object.values(fileRow).forEach((cell) => {
    const fileHeader: FileHeader = cell["fileHeader"];
    const value = cell["value"];
    const passedValidations = lodash.isEmpty(fileHeader.validationFuncs)
      ? true
      : !fileHeader.validationFuncs.some(
          (validationFunc) => !validationFunc(value, rowValues),
        );

    valid[fileHeader.key] = passedValidations;
    if (!passedValidations) {
      error = `Invalid ${fileHeader.header}`;
    }
  });
  return { valid, error, fileRow };
}

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const convertPriceToCents = (value?: string | number): number => {
  if (!value) {
    return 0;
  }
  if (lodash.isNumber(value)) {
    return value * 100;
  }
  const striped = value.replace(/[^\d.]/g, "");
  const strToNum = parseFloat(striped);
  return strToNum * 100;
};

export const convertToDate = (value?: string | Date): Date | undefined => {
  if (!value || !dayjs(value).isValid()) {
    return undefined;
  }
  return dayjs(value).toDate();
};

const isBlank = (value: any) => {
  if (lodash.isNil(value) || (lodash.isString(value) && value.length === 0)) {
    return true;
  }
  return false;
};

const convertNumberToCurrency = (value: number) => {
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

const convertStringToCurrency = (value: string) => {
  const striped = value.replace(/[^\d.]/g, "");
  const strToNum = parseFloat(striped);
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(strToNum);
};

export const convertToCurrency = (value: string | number) => {
  if (lodash.isNumber(value)) {
    return convertNumberToCurrency(value);
  } else if (isBlank(value) || !lodash.isString(value)) {
    return "$0.00";
  }
  return convertStringToCurrency(value);
};

const currencyValueGetter = (value: string, row: any) => {
  if (lodash.isNumber(value)) {
    return convertNumberToCurrency(value);
  } else if (isBlank(value) || !lodash.isString(value)) {
    return "";
  }
  return convertStringToCurrency(value);
};

const isRequired = (value: any, row: any): boolean => {
  return isBlank(value) ? false : true;
};

const isDate = (value: any, row: any): boolean => {
  return dayjs(value).isValid();
};

const isNumber = (value: any): boolean => {
  try {
    return lodash.isNumber(lodash.toNumber(value));
  } catch (error) {
    return false;
  }
};

const isPrice = (value: any, row: any): boolean => {
  if (!isBlank(value) && (lodash.isString(value) || isNumber(value))) {
    return true;
  }
  return false;
};

const isValidOtherColumns =
  (otherColumnKeys: Array<string>) =>
  (value: any, row: any): boolean => {
    if (
      !isBlank(value) ||
      (isBlank(value) && otherColumnKeys.some((key) => !isBlank(row[key])))
    ) {
      return true;
    }
    return false;
  };

const convertPrice = (value: any): any => {
  if (!isBlank(value) && lodash.isString(value)) {
    return convertStringToCurrency(value);
  }
  return "";
};

const valueSetter =
  (
    fileHeaderKey: string,
    validationFuncs: Array<ValidationFunc>,
    manipulateValue?: (arg: any) => any,
  ) =>
  (value: any, row: any) => {
    let newValue = value;
    if (manipulateValue) {
      newValue = manipulateValue(value);
    }
    const valid = row["valid"];
    valid[fileHeaderKey] = lodash.isEmpty(validationFuncs)
      ? true
      : !validationFuncs.some(
          (validationFunc) => !validationFunc(newValue, row),
        );
    let error = row["error"];
    if (!valid[fileHeaderKey]) {
      error = `Invalid ${fileHeaderKey}`;
    } else {
      error = undefined;
    }
    return { ...row, error, valid, [fileHeaderKey]: newValue };
  };

const sortKey = (key: string) =>
  [
    "invoiceDate",
    "invoiceNumber",
    "poNumber",
    "poDate",
    "soldToName",
    "soldToAddress",
    "shipToName",
    "shipToAddress",
    "directClassOfTrade",
    "directClassOfTradeStatus",
    "facilityId",
    "facilityHin",
    "facilityDea",
    "contractId",
    "contractIdDescription",
    "ndc",
    "productDescription",
    "quantity",
    "unitPrice",
    "invoiceAmount",
    "monthlyCpp",
    "quarterlyCpp",
    "transactionType",
  ].indexOf(key);

export function fileHeadersByFileUtilityType(
  fileUtilityType: FileUtilityType | undefined,
): { [key: string]: FileHeader } | undefined {
  if (fileUtilityType === "sales") {
    return {
      invoiceDate: {
        key: "invoiceDate",
        header: "Invoice Date",
        required: true,
        objectType: "date",
        sortKey: sortKey("invoiceDate"),
        valueGetter: (value, row) => {
          return dayjs(value).isValid() ? dayjs(value).toDate() : undefined;
        },
        valueSetter: valueSetter("invoiceDate", [isRequired, isDate]),
        validationFuncs: [isRequired, isDate],
      },
      invoiceNumber: {
        key: "invoiceNumber",
        header: "Invoice Number",
        objectType: "number",
        required: false,
        sortKey: sortKey("invoiceNumber"),
        valueSetter: valueSetter("invoiceNumber", [isNumber]),
        validationFuncs: [isNumber],
      },
      poNumber: {
        key: "poNumber",
        header: "P.O. Number",
        objectType: "number",
        required: false,
        sortKey: sortKey("poNumber"),
        valueSetter: valueSetter("poNumber", [isNumber]),
        validationFuncs: [isNumber],
      },
      poDate: {
        key: "poDate",
        header: "P.O. Date",
        objectType: "date",
        required: false,
        sortKey: sortKey("poDate"),
        valueGetter: (value, row) => {
          return dayjs(value).isValid() ? dayjs(value).toDate() : undefined;
        },
        valueSetter: valueSetter("poNumber", [isDate]),
        validationFuncs: [isDate],
      },
      soldToName: {
        key: "soldToName",
        header: "Sold to Name",
        objectType: "string",
        sortKey: sortKey("soldToName"),
        required: true,
        valueSetter: valueSetter("soldToName", [
          isValidOtherColumns(["shipToName"]),
        ]),
        validationFuncs: [isValidOtherColumns(["shipToName"])],
      },
      soldToAddress: {
        key: "soldToAddress",
        header: "Sold to Address",
        objectType: "string",
        required: true,
        sortKey: sortKey("soldToAddress"),
        valueSetter: valueSetter("soldToAddress", [
          isValidOtherColumns(["shipToAddress"]),
        ]),
        validationFuncs: [isValidOtherColumns(["shipToAddress"])],
      },
      shipToName: {
        key: "shipToName",
        header: "Ship to Name",
        objectType: "string",
        required: true,
        sortKey: sortKey("shipToName"),
        valueSetter: valueSetter("shipToName", [
          isValidOtherColumns(["soldToName"]),
        ]),
        validationFuncs: [isValidOtherColumns(["soldToName"])],
      },
      shipToAddress: {
        key: "shipToAddress",
        header: "Ship to Address",
        objectType: "string",
        required: true,
        sortKey: sortKey("shipToAddress"),
        valueSetter: valueSetter("shipToAddress", [
          isValidOtherColumns(["soldToAddress"]),
        ]),
        validationFuncs: [isValidOtherColumns(["soldToAddress"])],
      },
      directClassOfTrade: {
        key: "directClassOfTrade",
        header: "Direct Class of Trade",
        objectType: "string",
        required: true,
        sortKey: sortKey("directClassOfTrade"),
        valueSetter: valueSetter("directClassOfTrade", [isRequired]),
        validationFuncs: [isRequired],
      },
      directClassOfTradeStatus: {
        key: "directClassOfTradeStatus",
        header: "Direct Class of Trade Status",
        objectType: "string",
        required: true,
        sortKey: sortKey("directClassOfTradeStatus"),
        valueSetter: valueSetter("directClassOfTradeStatus", [isRequired]),
        validationFuncs: [isRequired],
      },
      facilityId: {
        key: "facilityId",
        header: "Facility ID",
        objectType: "string",
        required: false,
        sortKey: sortKey("facilityId"),
        valueSetter: valueSetter("facilityId", [
          isValidOtherColumns(["facilityDea", "facilityHin"]),
        ]),
        validationFuncs: [isValidOtherColumns(["facilityDea", "facilityHin"])],
      },
      facilityHin: {
        key: "facilityHin",
        header: "Facility HIN",
        objectType: "string",
        required: false,
        sortKey: sortKey("facilityHin"),
        valueSetter: valueSetter("facilityHin", [
          isValidOtherColumns(["facilityDea", "facilityId"]),
        ]),
        validationFuncs: [isValidOtherColumns(["facilityDea", "facilityId"])],
      },
      facilityDea: {
        key: "facilityDea",
        header: "Facility DEA",
        objectType: "string",
        required: false,
        sortKey: sortKey("facilityDea"),
        valueSetter: valueSetter("facilityDea", [
          isValidOtherColumns(["facilityHin", "facilityId"]),
        ]),
        validationFuncs: [isValidOtherColumns(["facilityHin", "facilityId"])],
      },
      contractId: {
        key: "contractId",
        header: "Contract ID",
        objectType: "string",
        required: false,
        sortKey: sortKey("contractId"),
        valueSetter: valueSetter("contractId", []),
        validationFuncs: [],
      },
      contractIdDescription: {
        key: "contractIdDescription",
        header: "Contract ID Description",
        objectType: "string",
        required: false,
        sortKey: sortKey("contractIdDescription"),
        valueSetter: valueSetter("contractIdDescription", []),
        validationFuncs: [],
      },
      ndc: {
        key: "ndc",
        header: "NDC",
        objectType: "string",
        required: true,
        sortKey: sortKey("ndc"),
        valueSetter: valueSetter("ndc", [isRequired]),
        validationFuncs: [isRequired],
      },
      productDescription: {
        key: "productDescription",
        header: "Product Description",
        objectType: "string",
        required: true,
        sortKey: sortKey("productDescription"),
        valueSetter: valueSetter("productDescription", [isRequired]),
        validationFuncs: [isRequired],
      },
      quantity: {
        key: "quantity",
        header: "Quantity",
        objectType: "number",
        required: true,
        sortKey: sortKey("quantity"),
        valueSetter: valueSetter("quantity", [isRequired, isNumber]),
        validationFuncs: [isRequired, isNumber],
      },
      unitPrice: {
        key: "unitPrice",
        header: "Unit Price",
        objectType: "string",
        required: true,
        sortKey: sortKey("unitPrice"),
        price: true,
        valueGetter: currencyValueGetter,
        valueSetter: valueSetter(
          "unitPrice",
          [isRequired, isPrice],
          convertPrice,
        ),
        validationFuncs: [isRequired, isPrice],
      },
      invoiceAmount: {
        key: "invoiceAmount",
        header: "Invoice Amount",
        objectType: "string",
        required: true,
        sortKey: sortKey("invoiceAmount"),
        price: true,
        valueGetter: currencyValueGetter,
        valueSetter: valueSetter(
          "invoiceAmount",
          [isRequired, isPrice],
          convertPrice,
        ),
        validationFuncs: [isRequired, isPrice],
      },
      monthlyCpp: {
        key: "monthlyCpp",
        header: "Monthly CPP",
        objectType: "string",
        required: true,
        sortKey: sortKey("monthlyCpp"),
        price: true,
        valueGetter: currencyValueGetter,
        valueSetter: valueSetter("monthlyCpp", [isPrice], convertPrice),
        validationFuncs: [isPrice],
      },
      quarterlyCpp: {
        key: "quarterlyCpp",
        header: "Quarterly CPP",
        objectType: "string",
        required: true,
        sortKey: sortKey("quarterlyCpp"),
        price: true,
        valueGetter: currencyValueGetter,
        valueSetter: valueSetter("quarterlyCpp", [isPrice], convertPrice),
        validationFuncs: [isPrice],
      },
      transactionType: {
        key: "transactionType",
        header: "Transaction Type",
        objectType: "singleSelect",
        valueOptions: [
          "Sales",
          "Chargeback",
          "Shortage",
          "Overage",
          "Pricing Adjustment",
          "Shelf Stock Adjustment",
          "Return",
          "Not AMP Eligible Labeler/Product",
        ],
        required: true,
        sortKey: sortKey("transactionType"),
        valueSetter: valueSetter("transactionType", []),
        validationFuncs: [],
      },
    };
  }
  return undefined;
}
