import { isEmpty, isNil } from "lodash";
import React from "react";

import { BusinessUnit } from "../types";

interface IBusinessUnitContext {
  businessUnit?: BusinessUnit;
  loadedBusinessUnitStorage: boolean;
  selectBusinessUnit: (b: BusinessUnit | undefined) => void;
}

const BusinessUnitContext = React.createContext<Partial<IBusinessUnitContext>>(
  {},
);

export function BusinessUnitProvider({
  children,
}: {
  children: React.ReactElement;
}) {
  const [loadedBusinessUnitStorage, setLoadedBusinessUnitStorage] =
    React.useState(false);
  const [businessUnit, setBusinessUnit] = React.useState<
    BusinessUnit | undefined
  >();

  React.useEffect(() => {
    const storedBusinessUnit = window.localStorage.getItem(
      process.env.REACT_APP_BUSINESS_UNIT_LOCAL_STORAGE!,
    );
    if (!(isNil(storedBusinessUnit) || isEmpty(storedBusinessUnit))) {
      const data = JSON.parse(storedBusinessUnit);
      setBusinessUnit(data);
    }
    setLoadedBusinessUnitStorage(true);
  }, []);

  const selectBusinessUnit = (b: BusinessUnit | undefined) => {
    if (b) {
      window.localStorage.setItem(
        process.env.REACT_APP_BUSINESS_UNIT_LOCAL_STORAGE!,
        JSON.stringify(b),
      );
      setBusinessUnit(b);
    } else {
      window.localStorage.removeItem(
        process.env.REACT_APP_BUSINESS_UNIT_LOCAL_STORAGE!,
      );
      setBusinessUnit(undefined);
    }
  };

  return (
    <BusinessUnitContext.Provider
      value={{
        businessUnit,
        loadedBusinessUnitStorage,
        selectBusinessUnit,
      }}
    >
      {children}
    </BusinessUnitContext.Provider>
  );
}

export function useBusinessUnit() {
  return React.useContext(BusinessUnitContext);
}
