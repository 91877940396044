import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";

import { useFileUploaderContext } from "../context/fileUploader.context";

export default function UploadComplete() {
  const { submittedRows, month, year } = useFileUploaderContext();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box
        sx={{
          flexShrink: 1,
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <Typography variant="h5">Upload Complete</Typography>
        {submittedRows && month && year && (
          <Typography variant="body1">
            {submittedRows.length} rows uploaded for{" "}
            {dayjs().month(month).format("MMMM")} {year}.
          </Typography>
        )}
      </Box>
    </Box>
  );
}
