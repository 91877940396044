import { useQuery } from "react-query";

import { ListResponseData, Sale, User } from "../types";
import { handleDetail, handleError, handleList } from "./handlers";

export default {
  useList: ({
    query,
    page,
    perPage,
    businessUnitId,
    month,
    year,
  }: {
    page?: number;
    perPage?: number;
    query?: string;
    businessUnitId?: number;
    month?: number;
    year?: number;
  }) => {
    const params = businessUnitId
      ? [{ key: "businessUnitId", value: businessUnitId }]
      : [];
    if(month){
      params.push({ key: "month", value: month })
    }
    if(year){
      params.push({ key: "year", value: year })
    }
    return useQuery<ListResponseData<Sale>>({
      retry: 0,
      queryKey: ["sales", page, perPage, businessUnitId, query, month, year],
      queryFn: () =>
        handleList({
          page,
          perPage,
          query: query || "",
          params,
          baseUrl: "sales",
        }),
      onError: handleError,
    });
  },
  useDetail: (id?: number | string) => {
    return useQuery({
      queryKey: ["sale", id?.toString()],
      queryFn: () => handleDetail<User>({ baseUrl: "sales", id }),
      onError: handleError,
      enabled: !!id,
    });
  },
};
