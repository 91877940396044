import React from "react";
import { useDropzone } from "react-dropzone";

import { useFileUploaderContext } from "../context/fileUploader.context";

const baseStyle = {
  flex: 1,
  display: "flex",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function UploadSpreadsheet() {
  const { setWorksheetData, setFileUploaderState } = useFileUploaderContext();

  const onDrop = (acceptedFiles: any) => {
    worker.postMessage({ file: acceptedFiles[0] });
  };

  const worker = new Worker(
    URL.createObjectURL(
      new Blob([
        `\
/* load standalone script from CDN */
importScripts("https://cdn.sheetjs.com/xlsx-0.20.2/package/dist/xlsx.full.min.js");

/* this callback will run once the main context sends a message */
self.addEventListener('message', (e) => {
  try {
    /* Read file data */
    const ab = new FileReaderSync().readAsArrayBuffer(e.data.file);

    /* Parse file */
    const wb = XLSX.read(ab, {dense: true});
    const ws = wb.Sheets[wb.SheetNames[0]];

    /* Generate HTML */
    // const html = XLSX.utils.sheet_to_html(ws);

    /* Reply with result */
    postMessage({ ws });
  } catch(e) {
    /* Pass the error message back */
    postMessage({ws: String(e.message || e).bold() });
  }
}, false);
  `,
      ]),
    ),
  );

  worker.onmessage = function (e) {
    setWorksheetData && setWorksheetData(e.data.ws);
    if (e.data.ws) {
      setFileUploaderState &&
        setFileUploaderState("uploaded_valid_spreadsheet");
    }
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    maxFiles: 1,
    onDrop: onDrop,
  });

  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <div className="flex flex-col">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
        <em>(Only *.xlsx and *.csv files will be accepted)</em>
      </div>
    </div>
  );
}
