import { useParams } from "react-router-dom";

import UserApi from "../api/user.api";
import UserForm from "../components/users/UserForm";

export default function User() {
  const { id } = useParams();
  const { data } = UserApi.useDetail(id);
  console.log("user data:", data);
  return <>{data && <UserForm user={data} />}</>;
}
