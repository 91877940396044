import * as Sentry from "@sentry/browser";
import { jwtDecode } from "jwt-decode";
import { isEmpty, isNil } from "lodash";
import React from "react";

import { Session } from "../types";

type Props = {
  children: React.ReactElement;
};

interface ISessionContext {
  session?: Session;
  loadedSessionStorage: boolean;
  saveSessionData: (p: string | undefined) => void;
  logout: () => void;
}

const SessionContext = React.createContext<Partial<ISessionContext>>({});

export function SessionProvider({ children }: Props) {
  const [loadedSessionStorage, setLoadedSessionStorage] = React.useState(false);
  const [jwtToken, setJwtToken] = React.useState<string | undefined>(undefined);
  const [session, setSession] = React.useState<Session | undefined>();

  React.useEffect(() => {
    try {
      const storedJwtToken = window.localStorage.getItem(
        process.env.REACT_APP_PROFILE_LOCAL_STORAGE!,
      );
      if (!(isNil(storedJwtToken) || isEmpty(storedJwtToken))) {
        const data = jwtDecode<Session>(storedJwtToken);
        const currentTime = Date.now() / 1000;
        if (data.exp > currentTime) {
          setSession(data);
          console.log('setJwtToken', storedJwtToken)
          setJwtToken(storedJwtToken);
        } else {
          setSession(undefined);
          setJwtToken(undefined);
        }
      }
    } catch(error){
      setSession(undefined);
      setJwtToken(undefined);
    } finally {
      setLoadedSessionStorage(true);
    }
  }, []);

  React.useEffect(() => {
    if (jwtToken) {
      console.log('JWT TOKEN useEFFECT', jwtToken)
      window.localStorage.setItem(
        process.env.REACT_APP_PROFILE_LOCAL_STORAGE!,
        jwtToken,
      );
      // TODO
      // check timestamp if the user is still logged in, redirect and clear session if invalid
      if (process.env.NODE_ENV === "production" && session) {
        Sentry.setUser({
          id: session?.sub?.toString(),
          email: session?.email,
        });
      }
    }
    if (jwtToken === undefined) {
      window.localStorage.removeItem(
        process.env.REACT_APP_PROFILE_LOCAL_STORAGE!,
      );
    }
  }, [jwtToken]);

  const saveSessionData = (jwtToken: string | undefined) => {
    if (jwtToken) {
      const decoded = jwtDecode<Session>(jwtToken);
      setSession(decoded);
      console.log('saveSessionData', jwtToken)
      window.localStorage.setItem(
        process.env.REACT_APP_PROFILE_LOCAL_STORAGE!,
        jwtToken,
      );
    }
  };

  const logout = (): void => {
    setSession(undefined);
    setJwtToken(undefined);
    window.localStorage.removeItem(
      process.env.REACT_APP_PROFILE_LOCAL_STORAGE!,
    );
  };

  return (
    <SessionContext.Provider
      value={{
        session,
        loadedSessionStorage,
        saveSessionData,
        logout,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export function useSession() {
  return React.useContext(SessionContext);
}
