import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import React from "react";

import SaleApi from "../api/sale.api";
import TablePaginationActions from "../components/TablePaginationActions";
import { Sale } from "../types";
import { convertToCurrency } from "../utils";

export default function Sales() {
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [query, setQuery] = React.useState("");
  const [toggleFilter, setToggleFilter] = React.useState(false);

  const [datePickerMonth, setDatePickerMonth] = React.useState<Dayjs | null>(
    null,
  );
  const [datePickerYear, setDatePickerYear] = React.useState<Dayjs | null>(
    null,
  );
  const { data } = SaleApi.useList({
    query,
    page,
    perPage,
    month: datePickerMonth ? datePickerMonth.month() : undefined,
    year: datePickerYear ? datePickerYear.year() : undefined,
  });

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const rowsPerPage = 10;
  const emptyRows =
    page > 0 && data && data.data
      ? Math.max(0, (1 + page) * rowsPerPage - data.meta.itemCount)
      : 0;

  return (
    <>
      <Box>
        <Box>
          <Typography variant="h2">Sales</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <TextField
            id="filled-search"
            label="Search"
            type="search"
            variant="filled"
            fullWidth
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
          <Box
            sx={{
              flexShrink: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Button
              size="large"
              onClick={() => setToggleFilter((toggleFilter) => !toggleFilter)}
            >
              <FilterListIcon />
            </Button>
          </Box>
        </Box>
        {toggleFilter && (
          <Paper
            elevation={2}
            sx={{
              my: "1rem",
              p: "1rem",
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
            }}
          >
            <DatePicker
              label={"Month"}
              views={["month"]}
              value={datePickerMonth}
              onChange={(newValue) => {
                if (newValue) {
                  setDatePickerMonth(newValue);
                } else {
                  setDatePickerMonth(null);
                }
              }}
            />
            <DatePicker
              label={"Year"}
              views={["year"]}
              value={datePickerYear}
              onChange={(newValue) => {
                if (newValue) {
                  setDatePickerYear(newValue);
                } else {
                  setDatePickerYear(null);
                }
              }}
            />
          </Paper>
        )}
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Month</TableCell>
              <TableCell>Year</TableCell>
              <TableCell>NDC</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Unit Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.data.map((sale: Sale) => (
                <TableRow key={sale.id}>
                  <TableCell>
                    {dayjs().month(sale.month).format("MMMM")}
                  </TableCell>
                  <TableCell>{sale.year}</TableCell>
                  <TableCell>{sale.ndc}</TableCell>
                  <TableCell>{sale.quantity}</TableCell>
                  <TableCell>{convertToCurrency(sale.unitPrice)}</TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              {data && data.meta && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={5}
                  count={data.meta.itemCount}
                  rowsPerPage={perPage}
                  page={data.meta.page}
                  slotProps={{
                    select: {
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              )}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
