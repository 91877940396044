import { useMutation, useQuery } from "react-query";

import { BusinessUnit, BusinessUnitInput, ListResponseData } from "../types";
import {
  handleBasicPost,
  handleDetail,
  handleError,
  handleList,
  handleSave,
} from "./handlers";

export default {
  useList: ({ query }: { query?: string }) => {
    return useQuery<ListResponseData<BusinessUnit>>({
      queryKey: "businessUnits",
      queryFn: () => handleList({ query, baseUrl: "business-units" }),
    });
  },
  useDetail: (id?: number | string) => {
    return useQuery({
      queryKey: ["businessUnit", id?.toString()],
      queryFn: () =>
        handleDetail<BusinessUnit>({ baseUrl: "business-units", id }),
      onError: handleError,
      enabled: !!id,
    });
  },
  useSave: () => {
    return useMutation({
      mutationFn: (input: BusinessUnitInput) =>
        handleSave<BusinessUnit>({ baseUrl: "business-units", input }),
      onError: handleError,
    });
  },
  useDelete: () => {
    return useMutation({
      mutationFn: (id: number) =>
        handleBasicPost({ url: `business-units/${id}`, input: {} }),
      onError: handleError,
    });
  },
};
