import { Box, Button, TextField } from "@mui/material";
import React from "react";
import toast from "react-hot-toast";

import UserApi from "../../api/user.api";
import { User } from "../../types";
import UserPasswordReset from "./UserPasswordReset";

export default function UserForm({ user }: { user: User }) {
  const [name, setName] = React.useState<string>(user.name);
  const [email, setEmail] = React.useState<string>(user.email);
  const [openPasswordReset, setOpenPasswordReset] =
    React.useState<boolean>(false);

  const { mutateAsync } = UserApi.useSave();

  const handleSave = async () => {
    try {
      const result = await mutateAsync({ id: user.id, name, email });
      console.log("result", result);
      if (email !== user.email) {
        toast(
          "Successfully saved. An email was sent to the new address to verify.",
        );
      } else {
        toast.success("Successfully saved.");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to save user's data.");
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <TextField
          fullWidth={true}
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          fullWidth={true}
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Box>
      <UserPasswordReset
        user={user}
        open={openPasswordReset}
        handleClose={() => setOpenPasswordReset(false)}
      />
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <Button variant="contained" fullWidth={true} onClick={handleSave}>
          Save
        </Button>
        <Button
          color="secondary"
          variant={"contained"}
          fullWidth
          onClick={() => setOpenPasswordReset(true)}
        >
          Reset Password
        </Button>
      </Box>
    </Box>
  );
}
