import { Box, FormControl, InputLabel, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { useFileUploaderContext } from "../context/fileUploader.context";
import { FileUtilityType } from "../types";

export default function FileUtilityTypeSelector() {
  const { fileUtilityType, setFileUtilityType } = useFileUploaderContext();

  const handleChange = (event: SelectChangeEvent) => {
    const newFileUtilityType = event.target.value;
    if (newFileUtilityType) {
      setFileUtilityType &&
        setFileUtilityType(newFileUtilityType as FileUtilityType);
    }
  };

  return (
    <Box sx={{ maxWidth: "20rem" }}>
      <FormControl fullWidth>
        <InputLabel id="file-utility-type-select-label">File Type</InputLabel>
        <Select
          labelId="file-utility-type-select-label"
          id="file-utility-type-select"
          value={fileUtilityType}
          label="File Type"
          onChange={handleChange}
        >
          <MenuItem value={"sales"}>Sales</MenuItem>
          <MenuItem value={"chargebacks"}>Chargebacks</MenuItem>
          <MenuItem value={"rebate_credits"}>Rebate Credits</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
