import { Box, Button, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import React from "react";

import { useFileUploaderContext } from "../context/fileUploader.context";
import { excelHeaderName } from "../utils";

const showNumRows = 5;

export default function SelectHeaderRow() {
  const {
    worksheetData,
    setFileUploaderState,
    headerRowIndex,
    setHeaderRowIndex,
  } = useFileUploaderContext();

  const [initialRowIndex, setInitialRowIndex] = React.useState<number>(0);
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  const columns: GridColDef[] = setColumns({ worksheetData, initialRowIndex });
  const rows = setRows({ initialRowIndex, worksheetData, columns });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">Select Header row</Typography>
        {headerRowIndex && (
          <Button
            onClick={() =>
              setFileUploaderState &&
              setFileUploaderState("selected_header_index")
            }
          >
            Continue
          </Button>
        )}
      </Box>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          // rowHeight={38}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableMultipleRowSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
            if (newRowSelectionModel[0]) {
              setHeaderRowIndex &&
                setHeaderRowIndex(newRowSelectionModel[0] as number);
            } else {
              setHeaderRowIndex && setHeaderRowIndex(undefined);
            }
          }}
          rowSelectionModel={rowSelectionModel}
        />
      </div>
    </Box>
  );
}

function setRows({
  initialRowIndex,
  worksheetData,
  columns,
}: {
  columns: GridColDef[];
  initialRowIndex: number;
  worksheetData: any;
}) {
  const rows = [];
  for (let ri = 0; ri < worksheetData["!data"].length; ri++) {
    const row: { [key: string]: any } = {};
    row["id"] = ri;
    for (let ci = 0; ci < columns.length; ci++) {
      const key = columns[ci]["field"];
      const value = worksheetData["!data"][initialRowIndex + ri][ci];
      row[key] = value ? value["v"] : undefined;
    }
    rows.push(row);
  }
  return rows;
}

function setColumns({
  initialRowIndex,
  worksheetData,
}: {
  initialRowIndex: number;
  worksheetData: any;
}): GridColDef[] {
  let maxColumnLengthForVisibleRows = 0;
  for (let i = 0; i < showNumRows; i++) {
    const columnLength = worksheetData["!data"][initialRowIndex + i].length;
    if (columnLength > maxColumnLengthForVisibleRows) {
      maxColumnLengthForVisibleRows = columnLength;
    }
  }

  const columns = [];
  for (let i = 0; i < maxColumnLengthForVisibleRows; i++) {
    columns.push({
      field: `column${i}`,
      headerName: excelHeaderName(i),
      sortable: false,
      hideable: false,
    });
  }
  return columns;
}
