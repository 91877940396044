import { Autocomplete, TextField } from "@mui/material";
import React from "react";

import FileApi from "../api/file.api";
import { FileUtilityType, SavedHeaderSchema } from "../types";

type AutocompleteOption = {
  id: number;
  label: string;
};

export default function SelectSavedHeaderColumns({
  fileUtilityType,
  setSelectedHeaders,
  setSavedHeaderSchema,
}: {
  fileUtilityType?: FileUtilityType;
  setSelectedHeaders: (arg: { [key: number]: string }) => void;
  setSavedHeaderSchema: (arg: SavedHeaderSchema) => void;
}) {
  const [value, setValue] = React.useState<AutocompleteOption | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [query, setQuery] = React.useState<string>("");

  const { data } = FileApi.useFetchSavedHeaderSchemas({
    fileUtilityType,
    query,
  });

  const { data: savedHeaderSchema } = FileApi.useFetchSavedHeaderSchema(
    value && value?.id ? value.id : undefined,
  );

  React.useEffect(() => {
    if (savedHeaderSchema) {
      const newHeaders: { [key: number]: string } = {};
      savedHeaderSchema.savedHeaderColumns.forEach((savedHeaderColumn) => {
        newHeaders[savedHeaderColumn.fileHeaderColumn] =
          savedHeaderColumn.fileHeaderKey;
      });
      setSelectedHeaders(newHeaders);
      setSavedHeaderSchema(savedHeaderSchema);
    }
  }, [savedHeaderSchema]);

  if (!fileUtilityType) return <></>;

  const options = data
    ? data.map((savedHeaderSchema) => ({
        label: savedHeaderSchema.name,
        id: savedHeaderSchema.id,
      }))
    : [];

  return (
    <Autocomplete
      id="select-saved-header-columns"
      sx={{ width: "25rem" }}
      options={options}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      onChange={(
        event: any,
        newValue: { label: string; id: number } | null,
      ) => {
        setValue(newValue);
      }}
      inputValue={query}
      onInputChange={(event, newInputValue) => {
        setQuery(newInputValue);
      }}
      isOptionEqualToValue={(
        option: AutocompleteOption,
        value: AutocompleteOption | null,
      ) => {
        if (option.id === value?.id) {
          return true;
        }
        return false;
      }}
      renderInput={(params) => (
        <TextField {...params} label="Saved Header Mappings" />
      )}
    />
  );
}
