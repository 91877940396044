import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import BusinessUnitApi from "../api/businessUnit.api";
import { useBusinessUnit } from "../context/businessUnit.context";
import { BusinessUnit } from "../types";

type BusinessUnitOption = {
  label: string;
  value: BusinessUnit;
};

export default function IndexDashboard() {
  const navigate = useNavigate();

  const { selectBusinessUnit } = useBusinessUnit();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState<BusinessUnitOption | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const { data, isLoading } = BusinessUnitApi.useList({ query: inputValue });
  const options =
    data && data.data
      ? data.data.map((businessUnit: BusinessUnit) => ({
          label: businessUnit.name,
          value: businessUnit,
        }))
      : [];
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="h2">Dashboard</Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Autocomplete
            sx={{ width: "100%" }}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            value={value}
            onChange={(event: any, newValue: BusinessUnitOption | null) => {
              if (newValue) {
                selectBusinessUnit && selectBusinessUnit(newValue.value);
                setValue(newValue);
                navigate("/business");
              } else {
                selectBusinessUnit && selectBusinessUnit(undefined);
                setValue(null);
              }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            getOptionLabel={(option) => option.label}
            options={options}
            loading={isLoading}
            renderInput={(params) => (
              <TextField {...params} label={"Access Business Unit Details"} />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
}
