import { Box } from "@mui/material";
import React from "react";

import { useFileUploaderContext } from "../context/fileUploader.context";
import { FileUploaderState } from "../types";
import FileUtilityTypeSelector from "./FileUtilityTypeSelector";
import MatchColumnHeaders from "./MatchColumnHeaders";
import SelectHeaderRow from "./SelectHeaderRow";
import SelectMonthAndYear from "./SelectMonthAndYear";
import UploadComplete from "./UploadComplete";
import UploadSpreadsheet from "./UploadSpreadsheet";
import UploadSpreadsheetStepper from "./UploadSpreadsheetStepper";
import ValidateData from "./ValidateData";

/*
  STEPS

  1. select type of file, to know which headers we need to match, (sales, chargebacks, ...)
  2. upload file and get it loaded : check
  3. show a preview of the first four lines, then the person can select which row is the header
  4. once header is selected, we show the matcher that allows the user to select
     which header value goes to which key from the file type
  5. create a table with all the columns and rows for the user to scroll through
     this tbale should be editable
     also should do some validation on the data, and keep track of that globally to easy navigate to it

*/

export default function UploadSpreadsheetFlow() {
  const { fileUploaderState } = useFileUploaderContext();
  // TODO add Material Stepper, https://mui.com/material-ui/react-stepper/
  //    to show what state we are in

  // TODO check what state the user was in and then load the data from localstorage

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
      <UploadSpreadsheetStepper />
      <MainState fileUploaderState={fileUploaderState} />
    </Box>
  );
}

function MainState(
  { fileUploaderState }: { fileUploaderState?: FileUploaderState },
) {
  if (fileUploaderState === "initial_state") {
    return <SelectMonthAndYear />;
  } else if (fileUploaderState === "upload_screen") {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
        <FileUtilityTypeSelector />
        <UploadSpreadsheet />
      </Box>
    );
  } else if (fileUploaderState === "uploaded_valid_spreadsheet") {
    return <SelectHeaderRow />;
  } else if (fileUploaderState === "selected_header_index") {
    return <MatchColumnHeaders />;
  } else if (fileUploaderState === "validate_data") {
    return <ValidateData />;
  } else if (fileUploaderState === "complete") {
    return <UploadComplete />;
  }
  return <></>;
}

// const onSubmit = async () => {};

// if (worksheetData !== undefined) {
//   console.log(worksheetData["!data"][0][0]);
//   console.log(worksheetData["!data"][1][0]);
//   console.log(worksheetData["!data"][2][0]);
//   console.log(worksheetData["!data"][3][0]);

//   console.log(worksheetData["!data"][1].length);
//   return <div></div>;
// }
