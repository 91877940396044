import CheckIcon from "@mui/icons-material/Check";
import {
  Alert,
  Box,
  Button,
  Card,
  CssBaseline,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import SessionApi from "../api/session.api";
import welcomeBgImg from "../assets/welcome-bg.jpg";
import { HttpError } from "../types/http-error";

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: "auto",
  backgroundImage: `url(${welcomeBgImg})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "left",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    height: "100dvh",
  },
}));

const MainCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

export default function ForgotPassword() {
  const [counter, setCounter] = React.useState(60);
  const [startCounter, setStartCounter] = React.useState(false);

  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    if (startCounter) {
      counter > 0 &&
        setTimeout(() => {
          setCounter(counter - 1);
          if (counter <= 1) {
            setStartCounter(false);
          }
        }, 1000);
    }
  }, [counter, startCounter]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    try {
      await SessionApi.sendForgotPasswordLink(data.get("email") as string);
      setSuccess(true);
      setStartCounter(true);
    } catch (error) {
      const httpError = error as HttpError;
      if (httpError.code === 401) {
        toast.error("Invalid token");
      } else {
        toast.error("Internal Server Error");
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const validateInputs = () => {
    const email = document.getElementById("email") as HTMLInputElement;
    let isValid = true;
    if (!email.value) {
      setEmailError(true);
      setEmailErrorMessage("Email is required");
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }
    return isValid;
  };

  console.log("loading - count", loading, startCounter);

  return (
    <Grid container component={"main"} sx={{ height: "100vh" }}>
      <CssBaseline />
      <SignInContainer direction="column" justifyContent="space-between">
        <Stack
          sx={{
            justifyContent: "center",
            height: "100dvh",
            p: 2,
          }}
        >
          <MainCard variant="outlined">
            <Typography
              component="h1"
              variant="h4"
              align={"center"}
              sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
            >
              Forgot Password
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: 2,
              }}
            >
              {success && !loading && (
                <Alert
                  icon={<CheckIcon fontSize="inherit" />}
                  severity="success"
                >
                  Successfully sent an email with a reset password link.
                  <br />
                  <br />
                  <span>
                    Did not receive email link? Try again in {counter} seconds
                  </span>
                </Alert>
              )}
              <FormControl>
                <FormLabel htmlFor="password">Email</FormLabel>
                <TextField
                  error={emailError}
                  helperText={emailErrorMessage}
                  name={"email"}
                  placeholder="fake-email@prescription-analytics.com"
                  type="email"
                  id="email"
                  autoFocus
                  required
                  fullWidth
                  variant="outlined"
                  color={emailError ? "error" : "primary"}
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={validateInputs}
                disabled={loading || startCounter}
              >
                Send reset password link to Email
              </Button>
              <Box>
                <Link to="/login">Back to Login</Link>
              </Box>
            </Box>
          </MainCard>
        </Stack>
      </SignInContainer>
    </Grid>
  );
}
