import { Box, Button, Modal, TextField } from "@mui/material";
import React from "react";
import toast from "react-hot-toast";

import UserApi from "../../api/user.api";
import { User } from "../../types";

export default function UserPasswordReset(
  {
    user,
    open,
    handleClose,
  }: {
    user: User;
    open: boolean;
    handleClose: () => void;
  },
) {
  const [password, setPassword] = React.useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] =
    React.useState<string>("");

  const { mutateAsync, isLoading } = UserApi.useResetPassword({
    id: user.id,
    password,
    passwordConfirmation,
  });

  const handleSave = async () => {
    try {
      const result = await mutateAsync();
      toast.success("Successfully saved.");
      handleClose();
    } catch (error) {
      console.error(error);
      toast.error("Failed to reset user's password.");
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <TextField
            fullWidth={true}
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            required
          />
          <TextField
            fullWidth
            label={"Password Confirmation"}
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            type="password"
            required
          />
          <Button
            disabled={isLoading}
            color="primary"
            onClick={handleSave}
            variant={"contained"}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
