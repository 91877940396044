import { useMutation, useQuery } from "react-query";

import { User, UserInput } from "../types";
import {
  handleDelete,
  handleDetail,
  handleError,
  handleList,
  handleSave,
  handleBasicPost
} from "./handlers";
import queryClient from "./queryClient";

export default {
  useList: () => {
    return useQuery({
      retry: 0,
      queryKey: ["users"],
      queryFn: () => handleList<User[]>({ baseUrl: "users" }),
      onError: handleError,
    });
  },
  useDetail: (id?: number | string) => {
    return useQuery({
      queryKey: ["user", id?.toString()],
      queryFn: () => handleDetail<User>({ baseUrl: "users", id }),
      onError: handleError,
      enabled: !!id,
    });
  },
  useSave: () => {
    return useMutation({
      mutationFn: (input: UserInput) =>
        handleSave<User>({ baseUrl: "users", input }),
      onError: handleError,
      onSuccess: (user: User) => {
        queryClient.setQueryData(["user", user.id], user);
        queryClient.invalidateQueries({ queryKey: ["users"] })
      },
    });
  },
  useResetPassword: ({ id, password, passwordConfirmation }: { id: number, password: string, passwordConfirmation: string }) => {
    return useMutation({
      mutationFn: () =>
        handleBasicPost({ url: 'users/reset-password', input: { id, password, passwordConfirmation } })
    })
  },
  useDelete: () => {
    return useMutation({
      mutationFn: (id: number) => handleDelete({ baseUrl: "users", id }),
      onError: handleError,
    });
  },
};
