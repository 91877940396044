import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { findIndex, isArray, toNumber } from "lodash";
import React from "react";
import toast from "react-hot-toast";

import { useFileUploaderContext } from "../context/fileUploader.context";
import {
  CellType,
  ColumnHeaders,
  FileHeader,
  FileRow,
  SavedHeaderSchema,
} from "../types";
import {
  excelHeaderName,
  fileHeadersByFileUtilityType,
  validateRow,
} from "../utils";
import SaveHeaderSchemaDialog from "./SaveHeaderSchemaDialog";
import SelectSavedHeaderColumns from "./SelectSavedHeaderColumns";

/*
  Match the column headers

  show all the colum headers with the select drop down to match the column header
  the goal is to mat
*/

export default function MatchColumnHeaders() {
  const {
    headerRowIndex,
    worksheetData,
    fileUtilityType,
    setFileUploaderState,
    setColumnHeaders,
    setValidatedFileRows,
  } = useFileUploaderContext();

  const fileHeaders = fileHeadersByFileUtilityType(fileUtilityType);

  const [savedHeaderSchema, setSavedHeaderSchema] = React.useState<
    SavedHeaderSchema | undefined
  >(undefined);
  const [promptedUserToSaveHeaderSchema, setPromptedUserToSaveHeaderSchema] =
    React.useState<boolean>(false);
  const [openPromptToSaveHeaderSchema, setOpenPromptToSaveHeaderSchema] =
    React.useState<boolean>(false);

  const [selectedHeaders, setSelectedHeaders] = React.useState<{
    [key: number]: string;
  }>({});

  const updateSelectedHeader = ({
    fileHeaderKey,
    columnIndex,
  }: {
    fileHeaderKey?: string;
    columnIndex: number;
  }) => {
    setSelectedHeaders((headerObj) => {
      if (fileHeaderKey === undefined) {
        if (headerObj[columnIndex]) {
          delete headerObj[columnIndex];
        }
      } else {
        headerObj[columnIndex] = fileHeaderKey;
      }
      return { ...headerObj };
    });
  };

  if (!fileHeaders || !headerRowIndex) return <></>;

  const handleCancelSaveHeaderSchema = () => {
    setOpenPromptToSaveHeaderSchema(false);

    handleContinue();
  };

  const handleContinue = () => {
    if (!fileHeaders) return;
    let missingRequiredKey = undefined;
    const matchedAllRequiredHeaders = Object.values(fileHeaders).some(
      (fileHeader: FileHeader) => {
        if (fileHeader.required) {
          const res = !Object.values(selectedHeaders).includes(fileHeader.key);
          if (res) {
            missingRequiredKey = fileHeader.header;
          }
          return res;
        }
        return false;
      },
    );
    if (matchedAllRequiredHeaders) {
      console.log("here");
      toast.error(`Missing required column header: ${missingRequiredKey}`);
      return;
    }

    if (!savedHeaderSchema && !promptedUserToSaveHeaderSchema) {
      setPromptedUserToSaveHeaderSchema(true);
      setOpenPromptToSaveHeaderSchema(true);
      return;
    }
    //
    const columnHeaders: ColumnHeaders = {};
    Object.keys(selectedHeaders).forEach((columnIndex: string) => {
      const fileHeaderKey = selectedHeaders[parseInt(columnIndex)];
      columnHeaders[parseInt(columnIndex)] = Object.values(fileHeaders).find(
        (fileHeader) => fileHeader.key === fileHeaderKey,
      ) as FileHeader;
    });

    setColumnHeaders && setColumnHeaders(columnHeaders);
    const validatedFileRows = [];
    for (let i = 1; i < worksheetData["!data"].length; i++) {
      const rowIndex = headerRowIndex + i;
      const row = worksheetData["!data"][rowIndex];
      if (row && isArray(row)) {
        const parsedRow: FileRow = {};
        Object.keys(columnHeaders).forEach((columnHeaderIndexStr: string) => {
          const columnHeaderIndex = toNumber(columnHeaderIndexStr);
          const fileHeader: FileHeader = columnHeaders[columnHeaderIndex];
          const cell: CellType | undefined = row[columnHeaderIndex];
          let cellValue = undefined;
          if (cell) {
            cellValue = cell["v"];
            if (fileHeader.objectType === "date") {
              cellValue = cell["w"];
            }
            if (fileHeader.objectType === "singleSelect") {
              if (
                fileHeader.valueOptions &&
                !fileHeader.valueOptions.includes(cellValue)
              ) {
                cellValue = undefined;
              }
            }
          }
          parsedRow[fileHeader.key] = {
            fileHeader,
            value: cellValue,
          };
        });

        validatedFileRows.push(validateRow({ fileRow: parsedRow }));
      }
    }

    setValidatedFileRows && setValidatedFileRows(validatedFileRows);
    setFileUploaderState && setFileUploaderState("validate_data");
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <SelectSavedHeaderColumns
          fileUtilityType={fileUtilityType}
          setSavedHeaderSchema={setSavedHeaderSchema}
          setSelectedHeaders={setSelectedHeaders}
        />

        <Button variant="contained" onClick={handleContinue}>
          Continue
        </Button>
      </Box>
      {worksheetData["!data"][headerRowIndex].map(
        (column: any, columnIndex: number) => (
          <Box
            key={columnIndex}
            sx={{ display: "flex", flexDirection: "row", gap: "2rem" }}
          >
            <TextField
              value={`${excelHeaderName(columnIndex)} - ${column["v"]}`}
              sx={{ width: "100%", maxWidth: "25rem" }}
            />
            <SelectHeader
              columnIndex={columnIndex}
              fileHeaders={fileHeaders}
              updateSelectedHeader={updateSelectedHeader}
              selectedHeaders={selectedHeaders}
            />
          </Box>
        ),
      )}
      {fileUtilityType && (
        <SaveHeaderSchemaDialog
          open={openPromptToSaveHeaderSchema}
          fileUtilityType={fileUtilityType}
          handleCancel={handleCancelSaveHeaderSchema}
          handleContinue={handleContinue}
          selectedHeaders={selectedHeaders}
        />
      )}
    </>
  );
}

type SelectHeaderProps = {
  columnIndex: number;
  fileHeaders: { [key: string]: FileHeader };
  selectedHeaders: { [key: number]: string };
  updateSelectedHeader: ({
    fileHeaderKey,
    columnIndex,
  }: {
    fileHeaderKey?: string;
    columnIndex: number;
  }) => void;
};

type SelectedHeaderOptionType = {
  key: string;
  label: string;
  fileHeader: FileHeader;
};

function SelectHeader({
  columnIndex,
  fileHeaders,
  selectedHeaders,
  updateSelectedHeader,
}: SelectHeaderProps) {
  const [value, setValue] = React.useState<SelectedHeaderOptionType | null>(
    null,
  );

  React.useEffect(() => {
    if (selectedHeaders[columnIndex] !== value?.key) {
      const fileHeader = fileHeaders[selectedHeaders[columnIndex]];
      setValue({ key: fileHeader.key, label: fileHeader.header, fileHeader });
    }
  }, [selectedHeaders]);

  const selectedFileHeaderKeys = Object.values(selectedHeaders);
  const filteredFileHeaders = Object.values(fileHeaders).filter(
    (fileHeader) => {
      const res = findIndex(
        selectedFileHeaderKeys,
        (selectedFileHeaderKey) => selectedFileHeaderKey === fileHeader.key, // && selectedFileHeaderKey !== selectedHeaders[columnIndex]
      );
      return res === -1;
    },
  );

  const options: SelectedHeaderOptionType[] = filteredFileHeaders.map(
    (fileHeader: FileHeader) => ({
      key: fileHeader.key,
      label: fileHeader.header,
      fileHeader,
    }),
  );
  if (value) {
    options.push(value);
  }

  const handleChange = (
    event: React.SyntheticEvent,
    value: SelectedHeaderOptionType | null,
    reason: string,
  ) => {
    updateSelectedHeader({ columnIndex, fileHeaderKey: value?.key });
    setValue(value);
  };

  return (
    <Autocomplete
      disablePortal
      options={options}
      renderInput={(params) => <TextField {...params} label={"Header"} />}
      sx={{ width: "25rem" }}
      onChange={handleChange}
      value={value}
    />
  );
}
