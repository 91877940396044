import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import BusinessUnitApi from "../api/businessUnit.api";
import BusinessUnitForm from "../components/businessUnits/BusinessUnitForm";

export default function BusinessUnit() {
  const { id } = useParams();
  const { data } = BusinessUnitApi.useDetail(id);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box>
        <Typography variant={"h2"}>Business Unit</Typography>
      </Box>
      {data && <BusinessUnitForm businessUnit={data} />}
    </Box>
  );
}
